import { useEffect, useState } from "react";
import Input from "../../../components/templates/ui-update/input";
import PasswordInput from "../../../components/templates/ui-update/input/password";
import Checkbox from "../../../components/templates/ui-update/checkbox";
import Button from "../../../components/templates/ui-update/button";
import { userLogin } from "../../../api/user";
import { useRouter } from "next/router";
import Link from "next/link";
import { tryExtractError } from "../errorUtil";
import {
  formatToSafePhone,
  getDeviceTokenLocal,
  setPreAuthedToken,
} from "../../../state/hooks/useAuth/helper";
import sha256 from "sha256";
import { useRootApp } from "../../../state/hooks/useRootAppState/useRootAppState";
import HeadingTitle from "../../main/Home/Homepage/sub/HeadingTitle";

const LoginContainer = (props: any) => {
  const rootApp = useRootApp();
  const auth = rootApp.auth;
  const router = useRouter();

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  const prefix = router.pathname.startsWith("/[host]/mobile") ? "/mobile" : "";

  const performLogin = async () => {
    if (processing) {
      return;
    }

    setProcessing(true);

    const deviceToken = getDeviceTokenLocal() as string;

    const resp = await userLogin({
      email: userEmail,
      password: sha256(password),
      deviceToken
    });
    if (resp?.status !== 200) {
      setError(tryExtractError(resp, "User authentication failed."));
      setProcessing(false);
      return;
    }

    const data = resp?.data.data;

    if (data.mode === "capture-phone") {
      setPreAuthedToken(data.preauthToken as string);
      router.push(`${prefix}/auth/verify-phone/${formatToSafePhone(data.cellphone)}`);
    } else if (data.mode === "otp-password-capture") {
      router.push(`${prefix}/set-password/${data.resetToken}`);
    } else if (data.mode === "logged") {
      auth.applyLoginFinish(data);
      router.push(prefix + "/");
      // router.push("/profile")
    }

    console.log("login success", resp);
    setProcessing(false);
  };

  console.log("@prefix", prefix)

  return (
    <>
      <form 
        onSubmit={(ev) => {
          ev.preventDefault();
          performLogin();
        }}
        autoComplete="on"
        className="flex flex-col gap-[20px] md:max-w-[640px] md:w-full md:mx-auto p-[20px] md:py-[20px] ">
        <div className="flex flex-col p-[12px] gap-[20px] sup-card md:bg-transparent md:p-0 md:pt-[20px] rounded-[4px]">
          <div className="flex flex-col gap-[10px] md:gap-[12px]">
            <HeadingTitle content="LOG IN" />
            <p className="font-font-family-body text-[16px] font-normal leading-trim-both">
              Already an app user? Use your app login details here.
            </p>
          </div>

          {error && (
            <>
              <div className="flex gap-Spacing-2 w-full border-Colors-Semantic-Error-Alpha-10/[.76] border-[1px] rounded-Radius-3 px-Spacing-3 py-Spacing-3 bg-Colors-Semantic-Error-Alpha-6/[0.03] max-w-[329px] md:max-w-[390px]">
                <div className="relative h-Spacing-4 w-Spacing-4 shrink-0">
                  <svg>
                    <use href="/sprites/sprites.svg#warning" />
                  </svg>
                </div>
                <p className="text-Typography-Font-size-1 font-mont2 font-normal text-Colors-Semantic-Error-Alpha-11">
                  {error}
                </p>
              </div>
            </>
          )}

          <div className="flex flex-col gap-[20px] md:p-[12px] md:sup-card md:rounded-[8px]">
            <div className="flex flex-col gap-[8px]">
              <p className="font-font-family-body text-[14px] font-normal leading-trim-both">
                Mobile number / email
              </p>
              <Input
                onChange={(ev) => {
                  setUserEmail(ev.target.value);
                }}
                autoComplete="email"
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <p className="font-font-family-body text-[14px] font-normal leading-trim-both">
                Password
              </p>
              <PasswordInput
                onChange={(ev) => {
                  setPassword(ev.target.value);
                }}
                autoComplete="current-password"
              />
            </div>
            <Checkbox
              text="Keep me logged in"
              onChange={() => {
                console.log("keep me logged in");
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[20px] items-center">
          <Button
            text="Log in"
            bgColor=" bg-accents-primary-button sup-btn-login"

            onClick={() => {
              console.log({ email: userEmail, password });
              performLogin();
            }}
          />

          <Link
            href={`${prefix}/auth/forgot-password`}
            className="font-font-family-body text-[14px] font-normal leading-trim-both text-white text-accents-secondary-accent sup-link"
          >
            forgot password
          </Link>
        </div>
        <div className="flex flex-col gap-[20px]">
          <div className="flex flex-col gap-[10px]">
            <h2 className="font-font-family-brand-font text-[20px] font-black italic leading-trim-both leading-[20px] uppercase">
              FIRST TIME CUSTOMER
            </h2>
            <p className="font-font-family-body text-[16px] font-normal leading-trim-both">
              Signing up only takes a moment.
            </p>
          </div>
          <Link
            className="flex p-[10px] rounded-[4px] self-stretch justify-center items-center w-full text-white bg-accents-primary-accent min-h-[40px] sup-btn-signup"
            href={`${prefix}/signup`}
          >
            Sign up
          </Link>
        </div>
      </form>
    </>
  );
};

export default LoginContainer;
