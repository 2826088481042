
interface PropsType {
    content?: string;
    className?: string;
}

const HeadingTitle = (props: PropsType) => {
    return (<>
        <h2 className={`text-[20px] font-font-family-body font-black italic w-[99%] md:text-[40px] uppercase ${props.className || ""} sup-heading-title `}>
            {props.content || "FIND YOUR NEAREST STORE TO ORDER"}
        </h2>
    </>)
}


export default HeadingTitle;