import { useState } from "react";

interface PropsType {
  placeholder?: string;
  onChange?: (e: any) => void;
  inputType?: string;
  isErroredOut?: boolean;
  value?: string;
  autoComplete?: string;
}

const Input = (props: PropsType) => {
  const [isTouched, setIsTouched] = useState(false);

  return (
    <label
      className={`flex gap-[20px] py-[12px] px-[20px] rounded-[4px] self-stretch items-center w-full  border-solid border-[1px] sup-input ${
        props.isErroredOut && isTouched ? "border-red-800" : "border-white"
      } h-[48px]`}
    >
      <input
        defaultValue={ props.value || ""}
        className="w-full bg-transparent text-[16px] font-font-family-body font-normal placeholder:text-inherit outline-none"
        placeholder={props.placeholder ? props.placeholder : ""}
        type={props.inputType ? props.inputType : "text"}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        onFocus={() => {
          setIsTouched(true);
        }}
        autoComplete={props.autoComplete}
      />
    </label>
  );
};

export default Input;
