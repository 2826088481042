import { AxiosError, AxiosResponse } from "axios"
import { DataWrapper } from "../../api/http"

export const tryExtractError = (resp: AxiosResponse | AxiosError, fallbackErr?: string) => {
    if (resp instanceof Error) {
      const eresp = resp as unknown as AxiosError
      const data = eresp.response?.data as DataWrapper
      
      const dataMessage = data?.data
      if (dataMessage && typeof dataMessage === "string") {
        return dataMessage
      }

      return data?.errorMessage || fallbackErr || "Something went wrong."
    }

    const data = resp?.data;
    const dataMessage = data?.data
    if (dataMessage && typeof dataMessage === "string") {
      return dataMessage
    }
    

    return data?.errorMessage || fallbackErr || "Something went wrong."
  }
  