import { useState } from "react";

interface PropsType {
  onChange?: (e: any) => void;
  isErroredOut?: boolean;
  value?: string;
  autoComplete?: string;
}

const PasswordInput = (props: PropsType) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <label className={`flex gap-[20px] py-[12px] px-[20px] rounded-[4px] self-stretch items-center border-solid border-[1px] sup-input ${isTouched && props.isErroredOut ? "border-red-800" : "border-white" }`}>
      <input
        className="w-full bg-transparent text-[16px] font-font-family-body font-normal placeholder:text-inherit outline-none"
        type={showPassword ? "text" : "password"}
        onChange={props.onChange}
        onFocus={() => {
          setIsTouched(true);
        }}
        defaultValue={props.value}
        autoComplete={props.autoComplete}

      />
      <div
        className="w-[24px] h-[24px] shrink-0 relative cursor-pointer"
        onClick={() => togglePasswordVisibility()}
      >
        <svg>
          {showPassword ? (<>
            <use href="/sprites/sprites.svg#passwordIconClose" />          
          </>) : (<>
            <use href="/sprites/sprites.svg#passwordIcon" />
          
          </>)}

        </svg>
      </div>
    </label>
  );
};

export default PasswordInput;
