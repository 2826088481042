

interface PropsType {
  checked?: boolean;
  text: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  strict?: boolean;
}



const Checkbox = (props: PropsType) => {
  return (
    <label className="flex gap-[12px] justify-between w-full sup-checkbox">
      <p className="w-full text-[14px] leading-normal font-font-family-body font-normal my-auto">
        {props.text}
      </p>
      <input
        type="checkbox"
        disabled={props.disabled}
        defaultChecked={!props.strict ? props.checked : undefined}
        checked={props.strict ? props.checked : undefined}
        className="hidden peer"
        onChange={props.onChange}
      />

      {/* shows when unchecked */}
      <div className="w-[28px] h-[28px] my-auto relative flex-shrink-0 block peer-checked:hidden border-[1px]  rounded-[4px] sup-input sup-checkbox-unchecked" />


      <div className="w-[28px] h-[28px] my-auto relative flex-shrink-0 hidden peer-checked:block sup-checkbox-checked">

        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 28 28" fill="none"
          className="w-full h-full text-accents-secondary-accent"
        >
          <path d="M18 9.99963L12.634 17.6651C12.4903 17.8714 12.2562 17.9962 12.0048 18.0004C11.7534 18.0045 11.5153 17.8876 11.365 17.686L10 15.8663"
            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            stroke="currentColor"
          />
        </svg>

      </div>

    </label>
  );
};

export default Checkbox;
