interface PropsType {
  className?: string;
  bgColor?: string;
  text?: string;
  disabled?: boolean;
  onClick?: (ev: any) => void;
  buttonType?: "button" | "submit" | "reset";
}

const Button = (props: PropsType) => {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.buttonType ? props.buttonType : "button"}
      className={`flex p-[10px] rounded-[4px] self-stretch justify-center items-center w-full min-h-[40px] text-white sup-btn ${
        props.bgColor ? `bg-${props.bgColor}` : "bg-accents-primary-button"
      } ${props.disabled ? "opacity-60" : ""}  ${props.className || ""}`}
    >
      <p className="text-[14px] font-font-family-body font-semibold">
        {props.text ? props.text : "View all stores"}
      </p>
    </button>
  );
};

export default Button;
